import React, { useEffect, useState, useRef } from "react"
import Section from "../../layouts/core/Section"
import {
  Header,
  HeaderGroup,
  HeaderGroupCenter,
  HeaderGroupLeft,
  HeaderGroupRight,
} from "../../layouts/core/Header"
import Body from "../../layouts/core/Body"
import Title from "../../layouts/core/Title"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import Button from "react-bootstrap/Button"
import SpinnerComponent from "../../components/commons/SpinnerComponent"
import { useSelector } from "react-redux"
import { selectAccounts } from "../../redux/account/account.selectors"
import { SubHeader, SubHeaderGroup } from "../../layouts/core/SubHeader"
import useUserFeedbackMessage from "../../hooks/useUserFeedbackMessage"
import {
  getHashtagId,
  getHashtagRecentMedia,
  getHashtagTopMedia,
  getRecentlySearchedHashtags,
} from "../../services/InstagamService"
import ReelerButton from "../../components/commons/ReelerButton"
import * as S from "./InstagramSearch.styles"

import InfiniteScroll from "react-infinite-scroll-component"
import axios from "axios"
import ReelerTooltip from "../../components/commons/ReelerTooltip/ReelerTooltip"
import MediaObject from "./components/MediaObject"
import MediaObjectModal from "./components/MediaObjectModal/MediaObjectModal"
import NoContent from "../../components/commons/NoContent"
import { FaExclamation, FaTimesCircle } from "react-icons/fa"
import Text from "../../components/commons/Text"
import { Link } from "react-router-dom"
import { MAIN_ROUTES } from "../../constants/routes"
import Icon from "../../components/commons/Icon"

export default function InstagramSearchPage() {
  const [loading, setLoading] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [mediaObjects, setMediaObjects] = useState(null)
  const [nextPagingLink, setNextPagingLink] = useState(null)
  const [hashtagLimitSearch, setHashtagLimitSearch] = useState(null)
  const [isClearable, setIsCleaable] = useState(false)
  const [searchType, setSearchType] = useState("top")
  const [igBusinessAccountId, setIgBusinessAccountId] = useState(null)
  const account = useSelector(selectAccounts)
  const searchInput = useRef()
  const { setErrorMessage } = useUserFeedbackMessage()

  useEffect(() => {
    if (account) {
      setLoading(true)

      setIgBusinessAccountId(
        account?.integrations?.instagram?.accounts[0]
          ?.ig_business_account_profile?.id
      )

      setLoading(false)
      // Check how many ig search limit
    }
  }, [account])

  const handleSearch = async type => {
    if (!searchInput.current.value) return

    if (!igBusinessAccountId) return

    setIsSearching(true)
    setMediaObjects(null)
    // Get hashtag id

    const searchTerm = searchInput.current.value.replaceAll("#", "")
    const { long_lived_user_token } = account?.integrations?.instagram

    try {
      const hashtagId = await getHashtagId(
        igBusinessAccountId,
        searchTerm,
        long_lived_user_token
      )

      let response

      if (type === "top") {
        response = await getHashtagTopMedia(
          hashtagId,
          igBusinessAccountId,
          long_lived_user_token
        )
      } else {
        response = await getHashtagRecentMedia(
          hashtagId,
          igBusinessAccountId,
          long_lived_user_token
        )
      }

      if (response?.data) {
        setMediaObjects(response?.data)
      }

      if (response?.paging?.next) {
        setNextPagingLink(response?.paging?.next)
        setHasMore(true)
      } else {
        setNextPagingLink(null)
        setHasMore(false)
      }

      await checkHashtagLimitSearch(long_lived_user_token)

      setIsSearching(false)
    } catch (err) {
      let errorMsg = ""
      if (err.response.data.error.error_subcode === 2207034) {
        errorMsg =
          "You can query a maximum of 30 unique hashtags on behalf of your Instagram Business Account within a rolling, 7 day period. Once you query a hashtag, it will count against this limit for 7 days. Subsequent queries on the same hashtag within this time frame will not count against your limit, and will not reset its initial query 7 day timer."
      } else if (err.response.data.error.error_subcode === 2207024) {
        errorMsg =
          "The hashtag has to already exist on Instagram. If you want to use a new hashtag, please begin by posting the hashtag anywhere on Instagram – then please try again to register it in Reeler."
      } else if (err.response.data.error.error_subcode === 463) {
        errorMsg =
          "The connection to Instagram has expired and you need to refresh the Instagram connection. <a class='notification-link' href='/settings/integrations'>Refresh connection here</a>"

        // TODO: Update the account and remove the exisiting connection
      } else if (err.response.data.error.error_user_title != undefined) {
        errorMsg = err.response.data.error.error_user_title
      } else {
        errorMsg = err.response.data.error.message
      }
      setErrorMessage(errorMsg, 5000)
      setIsSearching(false)
      console.log(err)
    }
  }

  const handleSearchType = type => {
    setSearchType(type)
    if (!searchInput.current.value) return
    handleSearch(type)
  }

  const handleClearSearch = () => {
    searchInput.current.value = null
    setMediaObjects(null)
  }

  const handleLoadMoreMediaObjects = async () => {
    if (nextPagingLink) {
      try {
        const { data } = await axios({
          url: nextPagingLink,
          method: "get",
        })

        if (data?.data && mediaObjects) {
          setMediaObjects(prev =>
            prev ? [...prev, ...data?.data] : [...data?.data]
          )
        }

        if (data?.data?.length < 25) {
          setHasMore(false)
        }

        data?.paging?.next
          ? setNextPagingLink(data?.paging?.next)
          : setNextPagingLink(null)
      } catch (e) {
        console.log("Instagram Error", e)
      }
    }
  }

  useEffect(() => {
    if (account && igBusinessAccountId) {
      const { long_lived_user_token } = account?.integrations?.instagram

      if (long_lived_user_token) {
        checkHashtagLimitSearch(long_lived_user_token)
      }
    }
  }, [account, igBusinessAccountId])

  const checkHashtagLimitSearch = async accessToken => {
    try {
      const recentlySearchHashtags = await getRecentlySearchedHashtags(
        igBusinessAccountId,
        accessToken
      )
      setHashtagLimitSearch(recentlySearchHashtags?.data?.length)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Section>
      <Header>
        <HeaderGroup>
          <HeaderGroupLeft>
            <Title>Instagram search</Title>
          </HeaderGroupLeft>
          <HeaderGroupCenter></HeaderGroupCenter>
          <HeaderGroupRight></HeaderGroupRight>
        </HeaderGroup>
      </Header>
      <SubHeader>
        <SubHeaderGroup>
          <div className="d-flex flex-row align-items-center">
            {account ? (
              <>
                <Form.Control
                  as="select"
                  name="ig_business_account"
                  value={igBusinessAccountId}
                  onChange={e => setIgBusinessAccountId(e.target.value)}
                  style={{ width: "200px" }}
                  className="me-3"
                  disabled={isSearching}
                >
                  {account?.integrations?.instagram?.accounts.map(
                    (account, key) => (
                      <option
                        key={key}
                        value={account.ig_business_account_profile.id}
                      >
                        {account.ig_business_account_profile.username}
                      </option>
                    )
                  )}
                </Form.Control>
                <InputGroup
                  className="d-flex flex-row align-items-center me-3"
                  style={{
                    borderRadius: ".25rem",
                    background: "#fff",
                    border: "1px solid lightgrey",
                    width: "400px",
                  }}
                >
                  <InputGroup.Text
                    className="pe-0"
                    style={{ border: "none", background: "none" }}
                  >
                    #
                  </InputGroup.Text>
                  <Form.Control
                    ref={searchInput}
                    style={{ border: "none" }}
                    placeholder="Type the Instagram hashtag and press Enter"
                    aria-label="Search hashtag"
                    aria-describedby="Search hashtag"
                    disabled={
                      account?.integrations?.instagram === null || isSearching
                    }
                    onKeyUp={e =>
                      e.key === "Enter" ? handleSearch(searchType) : null
                    }
                  />
                  {mediaObjects ? (
                    <Icon tooltip="Clear search" onClick={handleClearSearch}>
                      <FaTimesCircle />
                    </Icon>
                  ) : null}
                </InputGroup>
                <ReelerTooltip text="Find the top Instagram posts and reels, as determined by Meta.">
                  <Form.Check
                    inline
                    label="Top"
                    name="searchType"
                    type="radio"
                    id="top-media"
                    checked={searchType === "top"}
                    onChange={e => handleSearchType("top")}
                    disabled={isSearching}
                  />
                </ReelerTooltip>
                <ReelerTooltip text="Find recent Instagram posts and reels (from the last 24 hours).">
                  <Form.Check
                    inline
                    label="Recent"
                    name="searchType"
                    type="radio"
                    id="recent-media"
                    checked={searchType === "recent"}
                    onChange={e => handleSearchType("recent")}
                    disabled={isSearching}
                  />
                </ReelerTooltip>
              </>
            ) : null}
          </div>
          <div>
            {hashtagLimitSearch ? (
              <ReelerTooltip
                placement="bottom"
                text="From each of your Instagram accounts, you can query up to 30 unique hashtags within a 7-day period, including those from your saved hashtag sources."
              >
                <div className="d-flex flex-row align-items-center">
                  <FaExclamation size={14} className="icon-color" />
                  <Text
                    size="md"
                    color="var(--reeler-icon)"
                  >{`${hashtagLimitSearch}/30`}</Text>
                </div>
              </ReelerTooltip>
            ) : null}
          </div>
        </SubHeaderGroup>
      </SubHeader>
      <Body>
        {account?.integrations?.instagram ? (
          <>
            <MediaObjectModal />
            {isSearching && !mediaObjects && <SpinnerComponent />}

            {mediaObjects && mediaObjects.length > 0 && (
              <InfiniteScroll
                style={{ overflow: "none" }}
                dataLength={mediaObjects.length}
                next={() => handleLoadMoreMediaObjects()}
                hasMore={hasMore}
                loader={
                  <div className="d-flex justify-content-center py-3">
                    <SpinnerComponent size="sm" />
                  </div>
                }
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>
                      You've reached the end — there's no more content to
                      display.
                    </b>
                  </p>
                }
              >
                <S.MediaObjectsContainer>
                  {mediaObjects.map(mediaObject => {
                    return (
                      <MediaObject
                        key={mediaObject.id}
                        mediaObject={mediaObject}
                      />
                    )
                  })}
                </S.MediaObjectsContainer>
              </InfiniteScroll>
            )}
            {mediaObjects && mediaObjects.length === 0 && !isSearching && (
              <NoContent text="No content found" />
            )}

            {!mediaObjects && !isSearching && (
              <div className="d-flex justify-content-center">
                <div className="w-75">
                  <NoContent text="Search for ’Top’ or ‘Recent’ Instagram posts containing a specific hashtag. The results won’t be imported to Reeler immediately, but you can scroll through them and select which content assets to import and store." />
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center pt-5">
            <img
              className="mb-3"
              height="150px"
              src="../../women_working_out.svg"
              alt=""
            />
            <p className="p-3">
              You have not connected any Instagram Business Account.
            </p>

            <Button
              as={Link}
              className="btn-main"
              to={MAIN_ROUTES.settingsIntegration.path}
            >
              Connect your account here
            </Button>
          </div>
        )}
      </Body>
    </Section>
  )
}
