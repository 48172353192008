import React, { useState, useEffect } from "react"
import ReelerButton from "../../../../components/commons/ReelerButton"
import { database } from "../../../../firebase"
import { useSelector } from "react-redux"
import { selectAccounts } from "../../../../redux/account/account.selectors"
import { CAMPAIGN_TYPE } from "../../../../constants"
import useUserFeedbackMessage from "../../../../hooks/useUserFeedbackMessage"
import ReelerTooltip from "../../../../components/commons/ReelerTooltip/ReelerTooltip"

export default function ImportInstagramButton({ media }) {
  const [isLoading, setIsLoading] = useState(false)
  const [isAlreadyImported, setIsAlreadyImported] = useState(false)
  const [isImporting, setIsImporting] = useState(false)
  const account = useSelector(selectAccounts)
  const { setErrorMessage, setSuccessMessage } = useUserFeedbackMessage()

  useEffect(() => {
    // Check if media is already imported

    if (account) {
      setIsLoading(true)

      database.assets
        .where("accountId", "==", account.id)
        .where("media.id", "==", media.id)
        .get()
        .then(querySnapshot => {
          if (!querySnapshot.empty) {
            setIsAlreadyImported(true)
          }
          setIsLoading(false)
        })
        .catch(error => {
          console.log(error)
          setIsLoading(false)
        })
    }
  }, [account])

  const handleImportMediaObject = async () => {
    console.log("importing instagram media")
    setIsImporting(true)

    const tags = {
      import: true,
      instagram: true,
    }

    let instagramMedia = {
      media,
      tags,
      accountId: account.id,
      campaignType: CAMPAIGN_TYPE.igHashtag.type,
      status: "new",
      createdAt: database.getCurrentTimestamp(),
    }

    database.instagramMedia
      .add(instagramMedia)
      .then(() => {
        setSuccessMessage("Instagram media imported successfully")
        setIsImporting(false)
        setIsAlreadyImported(true)
      })
      .catch(err => {
        console.log("Could not create a new instagram_media object", err)
        setErrorMessage("Could not import instagram media")
        setIsImporting(false)
      })
  }

  return (
    <ReelerTooltip
      text={
        isAlreadyImported && !isLoading
          ? "Asset is already imported to Reeler"
          : "Import asset to Reeler from Instagram"
      }
    >
      <ReelerButton
        size="sm"
        disabled={isLoading || isImporting}
        loading={isImporting || isLoading}
        dispatch={() => handleImportMediaObject()}
        text="Import"
        styleClass={isAlreadyImported ? "btn-secondary" : "btn-main"}
      />
    </ReelerTooltip>
  )
}
