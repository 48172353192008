import React, { useState } from "react"
import { Form } from "react-bootstrap"
import { capitalize } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import { selectAccounts } from "../../../../../redux/account/account.selectors"
import SpinnerComponent from "../../../../../components/commons/SpinnerComponent"
import { setCreatorFilterParameters } from "../../../../../redux/creators/creators.actions"
import { selectCreatorFilterParameters } from "../../../../../redux/creators/creators.selectors"

export default function CreatorListFilter() {
  const filterParameters = useSelector(selectCreatorFilterParameters)
  const account = useSelector(selectAccounts)
  const dispatch = useDispatch()

  const handleFilter = ({ target: { name, value } }) => {
    if (value !== "showAll") {
      dispatch(setCreatorFilterParameters({ [name]: value }))
      return
    }

    var newFilter = filterParameters

    delete newFilter[name]

    const isEmpty = Object.keys(newFilter)?.length === 0

    if (isEmpty) {
      dispatch(setCreatorFilterParameters(null))
    } else {
      dispatch(setCreatorFilterParameters(newFilter))
    }
  }

  if (!account) return <SpinnerComponent />

  return (
    <Form.Control
      name="list"
      size="sm"
      as="select"
      onChange={e => {
        handleFilter(e)
      }}
      defaultValue={(filterParameters && filterParameters["list"]) || "showAll"}
    >
      <option value="showAll">Show all lists</option>
      {account?.creatorLists?.map((option, index) => {
        return (
          <option key={index} value={option}>
            {capitalize(option)}
          </option>
        )
      })}
    </Form.Control>
  )
}
