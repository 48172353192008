import React, { useState } from "react"
import { database } from "../../../firebase"
import ReelerButton from "../../commons/ReelerButton"
import firebase from "firebase/app"
import { Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { selectAccounts } from "../../../redux/account/account.selectors"
import { setCreator } from "../../../redux/creators/creators.actions"
import { selectCreator } from "../../../redux/creators/creators.selectors"
import useUserFeedbackMessage from "../../../hooks/useUserFeedbackMessage"

export default function CreatorMute({ btn }) {
  const { setSuccessMessage, setErrorMessage } = useUserFeedbackMessage()
  const [isMuting, setIsMuting] = useState(false)
  const [open, setOpen] = useState(false)
  const creator = useSelector(selectCreator)
  const dispatch = useDispatch()

  const closeModal = () => {
    setOpen(false)
  }
  const openModal = () => {
    setOpen(true)
  }

  const handleMute = async () => {
    setIsMuting(true)
    try {
      console.log("Is muting creator")

      const updateDate = {
        muted: creator?.muted ? !creator.muted : true,
      }

      await database.creators.doc(creator.id).update(updateDate)
      dispatch(setCreator({ ...creator, ...updateDate }))
      setSuccessMessage("Creator muted")
      setIsMuting(false)
    } catch (error) {
      setIsMuting(false)
      setErrorMessage("Error muting creator")
      console.error("Error removing document: ", error)
    }
  }

  return (
    <>
      <div onClick={openModal}>{btn}</div>

      <Modal centered show={open} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to mute this creator?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This action will hide the creartor from the creator list and stop
            collecting new content from the creator. To unmute the creator,
            filter on muted creators in the creators list.{" "}
          </p>
        </Modal.Body>

        <Modal.Footer>
          <ReelerButton
            text="Close"
            dispatch={closeModal}
            styleClass="btn-secondary"
          />
          <ReelerButton
            text="Mute creator"
            dispatch={() => handleMute()}
            loading={isMuting}
            disabled={isMuting}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
