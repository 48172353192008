import React, { useState } from "react"
import { functions, database } from "../../../../firebase"
import { FaInstagram, FaCheck, FaExclamationCircle } from "react-icons/fa"
import { updateAccounts } from "../../../../redux/account/account.actions"
import {
  selectAccounts,
  selectAccountId,
} from "../../../../redux/account/account.selectors"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import "../../../App.css"
import ReelerButton from "../../../commons/ReelerButton"
import moment from "moment"
import SpinnerComponent from "../../../commons/SpinnerComponent"
import { Form } from "react-bootstrap"
import { selectCurrentUser } from "../../../../redux/user/user.selectors"
import { renderDate } from "../../../../utils/RenderDate"
import { USER_ROLES } from "../../../../constants/routes"
import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip"

const scope = [
  "instagram_basic",
  "instagram_manage_comments",
  "instagram_content_publish",
  "pages_show_list",
  "pages_read_engagement",
  "pages_manage_posts",
  "pages_manage_metadata",
  "public_profile",
  "business_management",
]
// const scope = [
//   "pages_show_list",
//   "instagram_basic",
//   "instagram_content_publish",
//   "pages_read_engagement",
//   "public_profile",
//   "instagram_manage_comments",
//   "pages_manage_posts",
// ]
// "pages_show_list,instagram_basic,instagram_content_publish,pages_read_engagement,public_profile"
//"pages_show_list,instagram_basic,instagram_manage_comments,instagram_content_publish,pages_read_engagement,pages_manage_posts,public_profile"

// https://developers.facebook.com/docs/instagram/business-login-for-instagram
// https://developers.facebook.com/docs/pages/access-tokens/

const InstagramIntegration = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const account = useSelector(selectAccounts)
  const accountId = useSelector(selectAccountId)
  const currentUser = useSelector(selectCurrentUser)

  const [error, setError] = useState(null)

  const getLongLivedUserAccessToken = async access_token => {
    var getLongLivedUserAccessToken = functions.httpsCallable(
      "getLongLivedUserAccessToken"
    )

    try {
      const { data } = await getLongLivedUserAccessToken({
        accessToken: access_token,
        accountId: account.id,
      })
      console.log("Client returned long lived access token", data)
      return data
    } catch (err) {
      console.log(err)
    }
  }

  const getLongLivedAccessToken = async access_token => {
    try {
      const { data } = await axios.get(
        `https://graph.facebook.com/v20.0/oauth/access_token?grant_type=fb_exchange_token&client_id=1197050214072285&client_secret=3967793ee7ef31a7bc9cb30a2a0462ef&fb_exchange_token=${access_token}`
      )
      console.log("Response facebook user page: ", data)
      return data
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * To get a Page access token, send a GET request to the /PAGE-ID endpoint using your User access token. Replace PAGE-ID and USER-ACCESS-TOKEN with your information.
   */

  const getLonglivedPageAccessToken = async (pageId, accessToken) => {
    // Need to check if the content is IMAGE or VIDEO, different API calls

    var apiUrl = `https://graph.facebook.com/${pageId}?fields=access_token&access_token=${accessToken}`

    try {
      const { data } = await axios.get(apiUrl)
      console.log("Response long lived PAGE access token: ", data)
      return data
    } catch (err) {
      console.log(err)
    }
  }

  const loadFacebookPages = async access_token => {
    const { data } = await axios({
      url:
        "https://graph.facebook.com/v20.0/me/accounts?fields=id%2Cname%2Cpicture%2Caccess_token%2Cinstagram_business_account&access_token=" +
        access_token,
      method: "get",
    })

    console.log("Response facebook user page: ", data)
    return data.data
  }

  const loadInstagramBusinessUser = async (businessAccountId, accessToken) => {
    try {
      const { data } = await axios({
        url: `https://graph.facebook.com/v16.0/${businessAccountId}?fields=username%2Cname%2Cprofile_picture_url&access_token=${accessToken}`,
        method: "get",
      })
      if (data) return data
    } catch (err) {
      console.log(err)
    }
  }

  const compareScope = (scope, grantedScope) => {
    // compare lengths - can save a lot of time
    //if (scope.length != grantedScope.length) return false

    // Check if all scope is granted
    for (var i = 0, l = scope.length; i < l; i++) {
      if (!grantedScope.includes(scope[i])) return false
    }
    return true
  }

  const calculateTimeLeft = () => {
    if (account?.integrations?.instagram?.createdAt) {
      // TODO there is a problem with date because in firestore it is a timestamp and in redux its a date so we need to check
      let date = new Date(account?.integrations?.instagram?.createdAt)

      date.setMonth(date.getMonth() + 2)
      console.log(date)

      return "Refresh before: " + moment(date).format("YYYY-MM-DD")
    }
  }

  const fbLogin = () => {
    setLoading(true)
    setError(null)
    // https://developers.facebook.com/docs/facebook-login/web
    window.FB.login(
      function (response) {
        if (response.status === "connected") {
          if (response.authResponse && response.authResponse.grantedScopes) {
            handleFBLoginResponse(response)
          } else {
            setError(
              "Reeler needs more permissions to function properly. Make sure you have sufficient permissions to connect Reeler to your Instagram Business account and grant Reeler access to all asked permissions."
            )
            setLoading(false)
          }
        } else {
          setError("You are not logged in to Facebook.")
          // The person is not logged into your webpage or we are unable to tell.
          setLoading(false)
        }
      },
      {
        scope: scope,
        return_scopes: true,
      }
    )
  }

  const handleFBLoginResponse = async response => {
    const authResponse = response.authResponse

    console.log("Granted permissions: " + response.authResponse.grantedScopes)
    console.log("Scope: " + scope)

    const grantedScope = response.authResponse.grantedScopes.split(",")

    let accessToken = response.authResponse.accessToken
    console.log("AuthResponse: " + JSON.stringify(response.authResponse))
    // Make this a server side call instead
    const longLivedUserToken = await getLongLivedAccessToken(accessToken)

    // TODO: Hantera paging också
    // Get ig account details
    const facebookPages = await loadFacebookPages(accessToken)

    if (facebookPages.length > 0) {
      // Save formatted ig accounts save to firestore
      let igAccounts = []
      const promises = facebookPages.map(async page => {
        if (page?.instagram_business_account) {
          return new Promise(async (resolve, reject) => {
            let igAccount = {
              page_id: page.id,
              page_name: page.name,
              page_profile_picture: page.picture.data.url,
            }

            const igBusinessUserProfile = await loadInstagramBusinessUser(
              page.instagram_business_account.id,
              page.access_token
            )
            igAccount["ig_business_account_profile"] = igBusinessUserProfile

            igAccount["long_lived_access_token"] =
              longLivedUserToken.access_token

            // Request a long lived page acces token
            const longLivedPageToken = await getLonglivedPageAccessToken(
              page.id,
              longLivedUserToken.access_token
            )
            igAccount["long_lived_page_access_token"] =
              longLivedPageToken.access_token

            console.log(igAccount)
            igAccounts.push(igAccount)
            resolve()
          })
        }
      })

      await Promise.all(promises)

      // Save to firestore
      const createdAt = new Date()

      try {
        await database.accounts.doc(accountId).update({
          "integrations.instagram.accounts": igAccounts,
          "integrations.instagram.long_lived_user_token":
            longLivedUserToken.access_token,
          "integrations.instagram.scope": grantedScope,
          "integrations.instagram.auth_response": authResponse,
          "integrations.instagram.createdAt": createdAt,
          "integrations.instagram.intergratedBy": currentUser.id,
        })

        // Update redux
        dispatch(
          updateAccounts({
            integrations: {
              instagram: {
                accounts: igAccounts,
                long_lived_user_token: longLivedUserToken.access_token,
                scope: grantedScope,
                authResponse: authResponse,
                createdAt: createdAt,
              },
            },
          })
        )

        // Check if there is a notification and close it
        let notificationQuerySnapshot = await database.notifications
          .where("accountId", "==", accountId)
          .where("message_type", "==", "instagram_reminder")
          .where("show", "==", true)
          .get()

        // Loop through and set show == false
        notificationQuerySnapshot.forEach(doc => {
          database.notifications.doc(doc.id).update({ show: false })
        })

        setLoading(false)
      } catch (err) {
        console.log(err)
        setError(
          "Reeler could not save the integration. Please contact Reeler support. "
        )
        setLoading(false)
      }
    } else {
      setError(
        "Reeler could not load any Facebook pages. Please contact Reeler support."
      )
      setLoading(false)
    }
  }

  const LoginButton = () => (
    <ReelerTooltip
      text={
        currentUser?.role !== USER_ROLES.owner
          ? "Only the owner can connect Instagram"
          : ""
      }
    >
      <ReelerButton
        loading={loading}
        dispatch={() => fbLogin()}
        text="Login with Facebook"
        styleClass="btn-facebook"
        disabled={currentUser?.role !== USER_ROLES.owner}
      />
    </ReelerTooltip>
  )

  return account ? (
    <div
      className="p-3"
      style={{
        border: "1px solid lightgrey",
        borderRadius: "5px",
        width: "300px",
      }}
    >
      <h5>
        <FaInstagram className="icon-color me-3" />
        Instagram & Facebook
      </h5>
      <p>
        Connect Reeler to your Instagram and Facebook accounts. Make sure that
        your Instagram accounts are Professional accounts that are connected to
        Facebook Pages. Once linked, all users in your Reeler account will be
        able to collect content from, and post content to, Instagram.
      </p>
      {error ? (
        <div className="d-flex flex-row align-items-center mb-3">
          <FaExclamationCircle
            style={{ color: "var(--reeler-danger)" }}
            className="me-2"
          />
          <span>{error}</span>
        </div>
      ) : null}

      {account?.integrations?.instagram?.accounts?.length > 0 ? (
        <div className="d-flex flex-column ">
          <div className="d-flex flex-row align-items-center mb-1">
            <FaCheck style={{ color: "var(--reeler)" }} className="me-2" />
            <span>{`Connected (${renderDate(
              account?.integrations?.instagram?.createdAt,
              "YYYY-MM-DD"
            )})`}</span>
          </div>
          <Form.Group controlId="ig_business_account" className="my-2">
            <Form.Control as="select" name="ig_business_account">
              {account?.integrations?.instagram?.accounts.map(
                (account, key) => (
                  <option key={key}>
                    {`${account?.ig_business_account_profile?.username} - 
                    ${account?.page_name}`}
                  </option>
                )
              )}
            </Form.Control>
          </Form.Group>
          <LoginButton />
        </div>
      ) : (
        <LoginButton />
      )}
    </div>
  ) : (
    <SpinnerComponent />
  )
}

export default InstagramIntegration
